/* Golab styles for Data Grids */
.MuiDataGrid-main,
.MuiTablePagination-displayedRows,
.MuiButtonBase-root-MuiIconButton-root,
/* .Mui-disabled, */
.MuiInputBase-root,
.MuiDataGrid-editInputCell,
.MuiDataGrid-cell,
.MuiDataGrid-columnHeaders,
.MuiTablePagination-root,
.MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem,
.MuiSelect-icon,
.MuiTablePagination-selectLabel {
	color: white !important;
	border-bottom: 1px solid rgb(88, 87, 87) !important;
}

.MuiIconButton-root {
	color: white !important;
}

.css-1hq6sye input {
	color: white !important;
}

.MuiDataGrid-cell,
.MuiDataGrid-columnHeader {
	border-bottom: light solid rgb(88, 87, 87) !important;
}

.MuiDataGrid-iconSeparator {
	color: rgb(88, 87, 87) !important;
}

.MuiDataGrid-root {
	border-color: rgb(88, 87, 87) !important;
}

.MuiSvgIcon-root {
	color: white !important;
}

/* .Mui-disabled, */
.MuiDataGrid-cell--editing {
	-webkit-text-fill-color: #fff !important;
}

.MuiDataGrid-footerContainer {
	border-top: 0 !important;
}

.MuiDataGrid-footerContainer > nav {
	padding: 12px;
}

.MuiDataGrid-footerContainer > nav > ul {
	margin-top: 0;
}

.MuiPaper-root {
	background-color: #212121 !important;
	color: #f2f2f2 !important;
	background-image: none !important;
}

.MuiList-root {
	background-color: #212121 !important;
	color: #f2f2f2 !important;
}

.MuiAutocomplete-popper {
	color: #f2f2f2 !important;
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
	padding-top: 16px;
}

.MuiAutocomplete-listbox {
	background-color: inherit;
}

.MuiAutocomplete-noOptions {
	background-color: transparent;
}

.MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
	padding-top: 16px !important;
}

.MuiCard-root {
	background-image: none !important;
}
